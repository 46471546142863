import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../contexts/UserContext';

const Notifications = ({ setNotifications }) => {
  const { username, accessToken } = useContext(UserContext); // Get username and accessToken from context
  const [competitions, setCompetitions] = useState([]);
  const [localNotifications, setLocalNotifications] = useState([]);

  const REPO_URL = 'owner/repo'; // Replace with the actual owner/repo

  // Fetch competition data
  const fetchUserCompetitions = async (username) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/competitions/user/${username}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Ensure the user is authenticated
            },
        });

        if (response.ok) {
            const data = await response.json();
            return data.result;
        } else {
            console.error('Failed to fetch user competitions:', response.statusText);
            return [];
        }
    } catch (error) {
        console.error('Error fetching user competitions:', error);
        return [];
    }
};


  // Check for updates in competition statuses
  const checkCompetitionUpdates = async (username) => {
    const latestCompetitions = await fetchUserCompetitions(username);
  
    competitions.forEach((comp) => {
      const latestComp = latestCompetitions.find((c) => c.id === comp.id);
  
      if (latestComp && latestComp.status !== comp.status) {
        const newNotification = {
          message: `Competition '${comp.name}' has changed status to '${latestComp.status}'`,
          read: false,
        };
  
        setLocalNotifications((prevNotifications) => [...prevNotifications, newNotification]);
      }
    });
  
    setCompetitions(latestCompetitions);
  };
  

  // Fetch PR data from GitHub
  const fetchPRs = async (repoUrl, username, accessToken) => {
    const apiUrl = `https://api.github.com/repos/${repoUrl}/pulls?state=all&creator=${username}`;
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `token ${accessToken}`,
        },
      });
      if (!response.ok) throw new Error(`GitHub API Error ${response.status}: ${response.statusText}`);
      const prs = await response.json();
      console.log('PRs data:', prs);
      return Array.isArray(prs) ? prs : []; // Ensure it returns an array
    } catch (error) {
      console.error('Error fetching PRs:', error);
      return [];
    }
  };

  // Check for updates in PR statuses
  const checkPRUpdates = async () => {
    const prs = await fetchPRs(REPO_URL, username, accessToken);

    prs.forEach((pr) => {
      if (pr.state === 'closed' && pr.merged_at && !pr.draft) {
        const newNotification = {
          message: `Your PR '${pr.title}' was approved and merged`,
          read: false,
        };
        setLocalNotifications((prev) => [...prev, newNotification]);
      }
    });
  };

  // Set up polling intervals for checking updates
  useEffect(() => {
    if (!username || !accessToken) return; // Don't proceed if user is not authenticated
  
    // Poll for updates every 60 seconds
    const intervalId = setInterval(() => checkCompetitionUpdates(username), 60000);
  
    // Initial fetch of user-specific competitions
    fetchUserCompetitions(username).then((data) => setCompetitions(data));
  
    return () => clearInterval(intervalId);
  }, [username, accessToken]);

  // Optionally render notifications
  return (
    <div>
      {localNotifications.length > 0 && (
        <div>
          <h2>Notifications</h2>
          <ul>
            {localNotifications.map((notification, index) => (
              <li key={index}>
                <p>{notification.message}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Notifications;