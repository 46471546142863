import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/EditProfile.css';

const EditProfile = ({ username }) => {
  const [avatar, setAvatar] = useState('');
  const [email, setEmail] = useState('');
  const [discord, setDiscord] = useState('');
  const [telegram, setTelegram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [bio, setBio] = useState('');
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/${username}`);
        const data = response.data;

        // Ensure data is sanitized
        setAvatar(data.avatar);
        setEmail(data.email);
        setDiscord(data.discord);
        setTelegram(data.telegram);
        setTwitter(data.twitter || '');
        setLinkedin(data.linkedin || '');
        setBio(data.bio || '');
        setError(null);
      } catch (error) {
        setError("Failed to load user data. Please try again later.");
        console.error('Error fetching user data', error);
      }
    };

    const storedUsername = localStorage.getItem('username');
    // Check username match to prevent unnecessary fetch
    if (storedUsername && storedUsername !== username) {
      navigate(`/edit-profile/${storedUsername}`);
    } else {
      fetchUserData();
    }
  }, [username, navigate]);

  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];
    
    // Validate file type and size before uploading
    if (file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const maxSize = 2 * 1024 * 1024; // 2 MB

      if (!allowedTypes.includes(file.type)) {
        alert('Invalid file type. Please upload an image (JPEG, PNG, GIF).');
        return;
      }

      if (file.size > maxSize) {
        alert('File size exceeds 2 MB. Please choose a smaller image.');
        return;
      }

      const formData = new FormData();
      formData.append('avatar', file);
      formData.append('username', username);

      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/upload-avatar`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setAvatar(response.data.avatarUrl);
      } catch (error) {
        console.error('Error uploading avatar', error);
      }
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateUrl = (url, platform) => {
    // Define regex patterns for each platform
    const patterns = {
      twitter: /^(https?:\/\/)?(www\.)?(x\.com|twitter\.com)\/.+$/, // Accepts all of Twitter (X)
      linkedin: /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub)\/.+$/, // Accepts all LinkedIn profiles
      discord: /^(https?:\/\/)?(www\.)?discord\.gg\/.+$/, // Accepts all Discord invite links
    };

    // Validate the URL based on the platform
    return patterns[platform] ? patterns[platform].test(url) : true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};

    // Validate email format
    if (!validateEmail(email)) {
      validationErrors.email = 'Invalid email format';
    }

    // Validate URLs
    if (twitter && !validateUrl(twitter, 'twitter')) {
      validationErrors.twitter = 'Invalid Twitter URL';
    }
    if (linkedin && !validateUrl(linkedin, 'linkedin')) {
      validationErrors.linkedin = 'Invalid LinkedIn URL';
    }
    if (discord && !validateUrl(discord, 'discord')) {
      validationErrors.discord = 'Invalid Discord URL';
    }

    // Validate bio length
    if (bio.length > 500) {
      validationErrors.bio = 'Bio cannot exceed 500 characters';
    }

    // Check for validation errors
    if (Object.keys(validationErrors).length > 0) {
      setValidationErrors(validationErrors);
      return;
    }

    try {
      const updateData = {
        avatar,
        email,
        discord,
        telegram,
        twitter,
        linkedin,
        bio,
      };

      // Ensure to send authorization token safely
      const token = localStorage.getItem('github_token');
      if (!token) {
        setError('Authorization token is missing. Please log in again.');
        return;
      }

      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/${username}`, updateData, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true
      });

      navigate(`/profile/${username}`);
    } catch (error) {
      console.error('Error updating user data', error);
      setError("Failed to update user data. Please try again later.");
    }
  };

  return (
    <div className="profile-container">
      <h1 className="title">Edit Profile</h1>
      <form className="profile-form" onSubmit={handleSubmit}>
        <div className="avatar-section">
          <img src={avatar || 'https://img.freepik.com/free-psd/3d-render-avatar-character_23-2150611746.jpg'} alt="Avatar" className="avatar" />
          <input type="file" id="avatar-upload" onChange={handleAvatarChange} hidden />

        </div>
        <div className="form-section">
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="darth.vader@starwars.com"
            />
            {validationErrors.email && <span className="error">{validationErrors.email}</span>}
          </div>
          <div className="form-group">
            <label>Discord</label>
            <input
              type="text"
              value={discord}
              onChange={(e) => setDiscord(e.target.value)}
              placeholder="SirVader1234"
            />
          </div>

          <div className="form-group">
            <label>X</label>
            <input
              type="text"
              value={twitter}
              onChange={(e) => setTwitter(e.target.value)}
              placeholder="Your username without the @"
            />
            {validationErrors.twitter && <span className="error">{validationErrors.twitter}</span>}
          </div>
          <div className="form-group">
            <label>LinkedIn</label>
            <input
              type="text"
              value={linkedin}
              onChange={(e) => setLinkedin(e.target.value)}
              placeholder="Your LinkedIn URL"
            />
            {validationErrors.linkedin && <span className="error">{validationErrors.linkedin}</span>}
          </div>
          <div className="form-group full-width">
            <label>Bio</label>
            <textarea
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              placeholder="Let the world know who you are"
            />
          </div>

        </div>
        <button className="signup-button" type="submit">Save</button>
      </form>
    </div>
  );
};

export default EditProfile;
