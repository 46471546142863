import React from 'react';
import styled from 'styled-components';

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  background-color: #1f1c1c;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
`;

const PolicyWrapper = styled.div`
  max-width: 800px;
  background-color: #2b2b2b;
  padding: 20px;
  border-radius: 10px;
  color: white;
  line-height: 1.6;
`;

const PolicyTitle = styled.h1`
  color: #f5f5f5;
  text-align: center;
  margin-bottom: 20px;
`;

const PolicySection = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  color: #e0e0e0;
  font-size: 1.5rem;
`;

const Paragraph = styled.p`
  color: #d3d3d3;
  margin-bottom: 10px;
`;

const PrivacyPolicy = () => {
  return (
    <PageWrapper>
      <PolicyWrapper>
        <PolicyTitle>Privacy Policy for CodeCall</PolicyTitle>
        <PolicySection>
          <SectionTitle>Effective Date</SectionTitle>
          <Paragraph>[Insert Date]</Paragraph>
        </PolicySection>
        <PolicySection>
          <SectionTitle>Information We Collect</SectionTitle>
          <Paragraph>
            When you sign up for CodeCall using the \"Connect via GitHub\" button, we collect:
            <ul>
              <li>Your GitHub username and public profile information.</li>
            </ul>
            You may also choose to provide additional information, including:
            <ul>
              <li>LinkedIn profile link</li>
              <li>Twitter profile link</li>
              <li>Email address</li>
              <li>GitHub repository links</li>
            </ul>
          </Paragraph>
        </PolicySection>
        <PolicySection>
          <SectionTitle>Code Submissions</SectionTitle>
          <Paragraph>
            When you participate in challenges or submit code via GitHub, we collect and store:
            <ul>
              <li>The code you submit for evaluation.</li>
              <li>Associated repository information, including commit history, files, and metadata.</li>
            </ul>
          </Paragraph>
        </PolicySection>
        <PolicySection>
          <SectionTitle>How We Use Your Information</SectionTitle>
          <Paragraph>
            We use the information we collect to:
            <ul>
              <li>Provide, operate, and improve the Platform.</li>
              <li>Evaluate and manage code submissions for challenges and projects.</li>
              <li>Facilitate communication between you and other users or businesses.</li>
              <li>Monitor and improve the security and performance of the Platform.</li>
            </ul>
          </Paragraph>
        </PolicySection>
        {/* Add more sections from your privacy policy here */}
      </PolicyWrapper>
    </PageWrapper>
  );
};

export default PrivacyPolicy;
